import { axiosInstance } from "../AxiosInstance";
import { ErrorType } from "../UpdateAccess";

interface responseProp {
  // data:any,
  [key: string]: string | any;
}
export async function updateProvidersModel(type: number | string, payload: any) {
  let response: { [key: string]: responseProp[] } | any = {};
  let error: ErrorType = {};

  try {
    await axiosInstance
      .post(`/super/admin/business/${type}`, payload)
      .then((resp) => {
        response = resp.data;
      });
  } catch (err: any) {
    error = err;
  }

  return { response, error };
}
