import React from 'react';
import MPosMain from '../components/mPos';

const MPosPage = () => {
    return(
        <>
            <MPosMain />
        </>
    )
}

export default MPosPage;