import React from 'react';
import UserTransactionComp from '../components/profile/transaction';

const UserTransactionsPage = () => {
    return(
        <>
            <UserTransactionComp />
        </>
    )
}

export default UserTransactionsPage;