import { combineReducers } from '@reduxjs/toolkit';
import toggleSettingsReducer from './slices/toggleSettingsSlice';
import permissionsPayloadSlice from './slices/permissionsPayloadSlice';
import loggedUser from './slices/loggedUser';
 // Import other reducers here if you have any

const rootReducer = combineReducers({
  toggleSettings: toggleSettingsReducer,
  permissionsPayload:permissionsPayloadSlice, 
  loggedAdmin:loggedUser
  // Add other reducers here
});

export default rootReducer;
