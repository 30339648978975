import React, { useState, useEffect } from 'react';
import { GraphCard, MainGraphWrap, BigCardsGrid, GraphTopImage } from '../../styles/dashboard/graphStyle';
import * as Icon from 'iconsax-react';
import { FlexedBtn } from '../../styles/reusable/index';
import CreateTicket from './createTicket';
import EmptyState from '../mPos/reusable/emptyState';
import axios from 'axios';
import { Link } from 'react-router-dom';

const OtherCardSection = () => {

    const [openTicket, setOpenTicket] = useState(false);

    const loggedAdmin = localStorage.getItem('tks') || '{}';

    // States
    const [tickets, setTickets] = useState('');
    const [ticketsMutable, setMutableTickets] = useState<any>([])
    const [openCreateTicket, setOpenCreateTicket] = useState(false);
    const [onProcess, setOnprocess] = useState(false);

    // Set States
    const [username, setUsername] = useState('')
    const [option, setOption] = useState('')
    const [amount, setAmount] = useState('')
    const [method, setMethod] = useState('')
    const [description, setDescription] = useState('')
    
    // Create Ticket
    const createTicket = (e:any) => {
        e.preventDefault();
        const basicCreatePayload = {
            username,
            option,
            method,
            amount,
            status: 'Open',
            description
        }

        const body = {
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "x-token": `${loggedAdmin}`
            },
            body: JSON.stringify(basicCreatePayload)
        }

        fetch(`${process.env.REACT_APP_BASE_URL}/tickets/create`, body).then((res) => {
            res.json().then((resp) => {
            }).catch((err) => {

            })
        })
    }

    // Get Tickets
    useEffect(() => {
        const loggedAdmin = localStorage.getItem('tks') || '{}';

        const body = {
            headers: { 
                "Content-Type": "application/json",
                "x-token": `${loggedAdmin}`
            },
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/admin/ticket/all`, body)
            .then(res => {
                    setTickets(res.data.data.data);
            }).catch(err => {})
    }, [])

    // States
    const [transactions, setTransactions] = useState('');

    // Get Transactions
    useEffect(() => {
        const loggedAdmin = localStorage.getItem('tks') || '{}';

        const body = {
            headers: { 
                "Content-Type": "application/json",
                "x-token": `${loggedAdmin}`
            },
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/admin/transaction/all`, body)
            .then(res => {
                setTransactions(res.data.data);
            }).catch(err => {})
    }, [])

    return(
        <>
            {
                openTicket ?
                    <CreateTicket 
                        closeFunc={() => setOpenTicket(false)}
                        setUsername={setUsername}
                        setOption={setOption}
                        setAmount={setAmount}
                        setMethod={setMethod}
                        setDescription={setDescription}
                        onProcess={onProcess}
                        handleAction={createTicket}
                    />
                    : null
            }
            <MainGraphWrap>
                <BigCardsGrid>
                    <GraphCard>
                        <GraphTopImage>
                            <img 
                                src='/images/msg.png'
                                alt='Random'
                            />
                        </GraphTopImage>
                        <h1>{tickets.length > 0 ? tickets.length : '0'}</h1>
                        <h4>Total Number of Open Ticket</h4>
                        <p>
                            <span>
                                Kindly use the buttons below to access all tickets yet unattended to and treat accordingly
                            </span>
                        </p>
                        {/* <FlexedBtn>
                            <button
                                onClick={() => setOpenTicket(true)}
                            >
                                <Icon.DocumentDownload />
                                New Ticket
                            </button>
                            <button
                                style={{
                                    background: '#fff',
                                    color: '#A5A5A5'
                                }}
                            >
                                Go to Inbox
                            </button>
                        </FlexedBtn> */}
                    </GraphCard>
                    <GraphCard>
                        <h1>{transactions.length > 0 ? transactions.length : '0'}</h1>
                        <h4>Total Transaction</h4>
                        <p>
                            <Link to='/transactions'>
                                <span>
                                    View total transactions on Mongoro
                                </span>
                            </Link>
                        </p>
                        {/* <img 
                            src={'/images/graph2.png'}
                            alt='Graph'
                        /> */}
                    </GraphCard>
                    <GraphCard>
                        <h4>Revenue by month</h4>
                        {/* <img 
                            src={'/images/graph.png'}
                            alt='Graph'
                        /> */}
                        <EmptyState />
                    </GraphCard>
                    <GraphCard>
                        <h4>Invoices Statistic</h4>
                        <EmptyState />
                        {/* <img 
                            src={'/images/chart3.png'}
                            alt='Graph'
                        /> */}
                    </GraphCard>
                </BigCardsGrid>
            </MainGraphWrap>
        </>
    )
}

export default OtherCardSection;