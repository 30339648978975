import React from 'react';
import TransactionsMain from '../components/transactions';

const TransactionsPage = () => {
    return(
        <>
            <TransactionsMain />
        </>
    )
}

export default TransactionsPage;