import React from 'react';
import { InnerWrapper, MainWrapper, NotFoundImage } from '../styles/reusable/404';
import { FlexedBtn } from '../styles/reusable/index';
import Typography from './mPos/reusable/typography';
import { Link } from 'react-router-dom';
import * as Icon from 'iconsax-react';

const NotFound = () => {
    return(
        <>
            <MainWrapper>
                <InnerWrapper>
                    <NotFoundImage 
                        src='/icons/404.png'
                        alt='Not Found'
                    />
                    <Typography 
                        text={'Page not found'}
                        fontSize='23px'
                        color='#202020'
                        fontWeight={700}
                        lineHeight={'0'}
                        
                    />
                    <Typography 
                        text={'The page you are looking for does not exist'}
                        fontSize='15px'
                        color='#202020'
                        lineHeight={'0'}
                        
                    />
                    <Link 
                        to='/'
                        style={{
                            margin: '2rem auto'
                        }}
                    >
                        <FlexedBtn
                           
                        >
                            <button>
                                <Icon.Home />
                                Dashboard
                            </button>
                        </FlexedBtn>
                    </Link>
                    
                </InnerWrapper>
            </MainWrapper>
        </>
    )
}

export default NotFound;