import React from 'react';
import LoginActivities from '../components/loginActivities/index';

const LoginActivitiesPage = () => {
    return(
        <>
            <LoginActivities />
        </>
    )
}

export default LoginActivitiesPage;