import React, { useRef } from 'react';
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, FlexedBtn, DateWrap, MenuSpace, DashboardInput, TableWrap, TableFlex, SmallTableSpace, TableName, Line, UserCard, IconCard,  HeaderItems, FilterSelect, IconBtn, UserProfileCard, StatusCard, GenericInputWrap } from '../../styles/reusable/index';
import SideBarWidget from '../mPos/reusable/sidebar';
import Header from '../mPos/reusable/header';
import { SettingsMainWrap } from '../../styles/settings';
import{ Editor } from '@tinymce/tinymce-react'

const BusinessAdvisoryMain = () => {
    
    const editorRef: any = useRef<any>(null);
    const log = (content: any) => {
    };

    return(
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <DescHeader>
                            <h3>Business Advisory</h3>
                        </DescHeader>
                        <div
                            style={{
                                margin: '3rem 0 0 0'
                            }}
                        >
                            <GenericInputWrap>
                                <label>Subject</label>
                                <input placeholder='Subject' />
                            </GenericInputWrap>
                        </div>
                        <div
                            style={{
                                margin: '2rem 0 0 0'
                            }}
                        >
                            <SettingsMainWrap
                                style={{
                                    borderRadius: '9px'
                                }}
                            >
                                <p>Edit Club Curriculum</p>
                                <Editor
                                    apiKey={
                                    'kbj2x5a39gexwf21jwt5n43oc51bob2gldsx1qk9q3pys8jm'
                                    }
                                    onInit={(evt: any, editor: any) =>
                                    (editorRef.current = editor)
                                    }
                                    initialValue="<p></p>"
                                    init={{
                                    height: 300,
                                    menubar: false,
                                    plugins: [
                                        'advlist',
                                        'autolink',
                                        'lists',
                                        'link',
                                        'image',
                                        'charmap',
                                        'preview',
                                        'anchor',
                                        'searchreplace',
                                        'visualblocks',
                                        'code',
                                        'fullscreen',
                                        'insertdatetime',
                                        'media',
                                        'table',
                                        'code',
                                        'help',
                                        'wordcount',
                                    ],
                                    toolbar:
                                        'undo redo | blocks | ' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                        'removeformat | help',
                                    content_style:
                                        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                    }}
                                    onEditorChange={log}
                                />
                            </SettingsMainWrap>
                        </div>
                        <FlexedBtn
                                style={{
                                    margin: '3rem 0 0 0%'
                                }}
                            >
                                <button>
                                    Save Changes
                                </button>
                            </FlexedBtn>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
    )
}

export default BusinessAdvisoryMain;