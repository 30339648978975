import { ClipLoader } from "react-spinners";
import "../../../styles/modals/invite.scss"
import { Button } from "../../../styles/reusable"
import { InputField, UnderlayText, ModalHeader, ModalWrap } from "../../../styles/reusable/index"
import { ModalChild } from "../../mPos/reusable/style"
import * as FeatherIcon from 'react-feather';
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/types/types";
import { error } from "console";
import { axiosInstance } from "../../../services/AxiosInstance";


interface InviteAdminProps {
    closeFunc: Function,
    handleSubmit:Function,
    message:string,
    heading:string,
    loading:boolean
}




const AccessPrompt: React.FC<InviteAdminProps> = ({ closeFunc, handleSubmit, heading, message, loading }) => {
 
    const permissionsPayload = useSelector((state: RootState) => state.permissionsPayload)
    const [payload, setPayload] = useState({
        email: "",
        category: permissionsPayload.categoryID,
    })

    const [empty, setEmpty] = useState<boolean>(false)

  
 


    return (
        <ModalWrap>
            <ModalChild>
                <ModalHeader>
                    <h3>{heading}</h3>
                    <i><FeatherIcon.X onClick={() => closeFunc()} /></i>
                </ModalHeader>
                <form >
                    <UnderlayText>
                        {message}
                    </UnderlayText>
                
                    
                    <Button
                        width='100%'
                        bg='var(--primary-color)'
                        color='#fff'
                        type='button'
                        disabled={loading}
                        onClick={() => { handleSubmit() }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              
                            }
                        }}
                        style={loading ? {
                            cursor: 'not-allowed',
                        } : {}
                        }
                    >
                        {/* Invite Admin */}
                        {loading ? <ClipLoader color='#fff' size={18} /> : 'Continue'}
                    </Button>
                </form>
            </ModalChild>
        </ModalWrap>
    )
}


export default AccessPrompt