import React from 'react';
import BusinessAdvisoryMain from '../components/businessAdvisory';

const BusinessAdvisoryPage = () => {
    return(
        <>
            <BusinessAdvisoryMain />
        </>
    )
}

export default BusinessAdvisoryPage;