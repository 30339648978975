import { createSlice } from "@reduxjs/toolkit";

export interface ToggleSettingsState {
  controlPanel: boolean;
  general: boolean;
  team: boolean;
  paymentMethod: boolean;
  permissions: boolean;
  permissionLevels:boolean;
  role:string,
  toAccess:string
}
const initialState: ToggleSettingsState = {
  controlPanel: false,
  general: false,
  team: false,
  paymentMethod: false,
  permissions: false,
  permissionLevels:false,
  role:"",
  toAccess:""
};

const toggleSettingsSlice = createSlice({
  name: "toggleSettings",
  initialState,
  reducers: {
    setToggleSettings: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setToggleSettings } = toggleSettingsSlice.actions;
export default toggleSettingsSlice.reducer;
