import styled from "styled-components";

export const MainHeaderCont = styled.div`
    width:90%;
    background: #ffffff;
    padding:0.8rem 5%;
    display:flex;
    align-items:Center;
    justify-content:space-between;

    @media (max-width:728px){
        padding:0.5rem 5%;
    }
`

export const LeftCont = styled.div`
    width:35%;
    position:relative;
    
    > input {
        width:77%;
        background: #FAFAFA;
        border-radius: 46px;
        padding:1rem 5% 1rem 18%;
    }

    > i {
        position:Absolute;
        left:1.5rem;
        top:55%;
        transform: translate(0, -55%);
    }

    @media (max-width:728px){
        display:none;
    }
`

export const  RightCont = styled.div`
    display:flex;
    align-items:Center;
    gap:60px;

    @media (max-width:728px){
        gap:10px;
    }
`
export const IconSection = styled.div`
    display:flex;
    align-items:Center;
    gap:20px;

    > i {
        width:2.5rem;
        height:2.5rem;
        display:flex;
        align-items:center;
        justify-content:center;
        position:relative;
        background: #EDF2F6;
        border-radius: 60px;
    }

    @media (max-width:728px){
        display:none;
        // visibility:hidden;
    }
`;

export const LoggedSection = styled.div`
    display:flex;
    align-items:center;
    gap:15px;
    position:relative;

    > div:nth-child(2){
        line-height:10px;
        
        > h3 {
            width:6rem;
            overflow:hidden;
            white-space:nowrap;
            text-overflow:ellipsis;
            font-size:14px;
        }
        > p {
            font-size:12px;
        }
    }
`

export const LoggedAvatar = styled.div`
    padding:0.8rem 1.2rem;
    background: #F42F4B;
    border-radius: 14px;
    font-size:18px;
    font-weight:600;
    color: #fff;
`

interface CountProps {
    bg: any;
} 

export const NotificationCount = styled.p<CountProps>`
    width:20px;
    height:20px;
    display:flex;
    align-items:Center;
    justify-content:Center;
    position:absolute;
    font-style: normal;
    top:-1.3rem;
    right:-0.5rem;
    font-size:10px;
    color: #fff;
    background: ${p => p.bg || '#216FED'};
    border-radius:5rem;
    border: 3px solid #EDF2F6;
`

export const MobileMenuController = styled.div`
    display:none;

    > i {
        font-size:20px;
    }

    @media (max-width:728px){
        display:block;
    }
`

export const AccountPopUp = styled.div`
    position:absolute;
    top:4rem;
    right:0;
    width:8rem;
    padding:0.5rem 1rem;
    background:#fff;
    z-index:1000;
    box-shadow: 0px 8px 40px rgba(9, 44, 76, 0.16);
    border-radius: 4px;

    div {
        display:flex;
        align-items:Center;
        gap:10px;
        cursor:pointer;

        :hover {
            opacity:0.6;
        }

        > img {
            width:1.2rem;
        }

        > p {
            font-weight: 300;
            font-size: 12px;
            line-height: 20px;
            color: #808080;
        }
    }
`