import { Edit } from "iconsax-react"
import "../../../styles/accounts/switchProvider.scss"
import { ChevronDown } from "react-feather"
import { useEffect, useState } from "react"
import GatewaysSettings from "./GatewaysSettings"
import { ChevronLeft } from "@mui/icons-material"
import CreateProvider from "../createProvider"
import { axiosInstance } from "../../../services/AxiosInstance"
import ActivateProvider from "../../../services/accounts/ActivateServiceProvider"
import { objectSwitch } from "../../../utils/modifiers/togglers"
import { ClipLoader } from "react-spinners"
import Alert from "../../mPos/reusable/alert"
import { useNavigate } from "react-router"


export default function SwitchProvider() {

    interface providerObj {
        [key: string]: string
    }
    const [providers, setProviders] = useState({
        all: [],
        selected: {} as providerObj,
        active: {} as providerObj,
    });

    const [show, setShow] = useState({
        selectProvider: false,
        providerSettings: false,
        createProvider: false,
    })

    const [operation, setOperation] = useState<{ [key: string]: any }>({
        pending: false,
        success: false,
        error: false,
        message: "",
    })



    useEffect(() => {
        getServiceProviders()
    }, [])




    async function getServiceProviders() {

        await axiosInstance.get(`/super/admin/business/service/providers/`)
            .then(resp => {
                setProviders({ ...providers, all: resp.data.data })
                if (resp.data.data.length > 0) {
                    for (let i = 0; i < resp.data.data.length; i++) {
                        const provider = resp.data.data[i]
                        if (provider.active) {
                            setProviders({ ...providers, active: provider, all: resp.data.data });
                        }
                    }
                }
            }).catch(err => {
                if (err.response.status === 403) {
                    localStorage.clear();
                    // navigate('/login');
                }
            })
    }



    async function handleActivateProvider() {
        setOperation((prev) => prev = objectSwitch(operation, "pending"));
        try {
            const { response, error } = await ActivateProvider(providers.selected.prid);

            if (error?.response) {
                setOperation((prev) => prev = objectSwitch(operation, "error"));
                setOperation({ ...operation, message: error.response.data?.message });
            } else if (response) {
                setOperation((prev) => prev = objectSwitch(operation, "success"));
                getServiceProviders();
            }
        } catch (err) {
            setOperation((prev) => prev = objectSwitch(operation, "error"));
        }
    }



    return (<div className="switch-provider-container">


        <h3 className="row-center">
            {show.providerSettings && <ChevronLeft className="pointer" onClick={() => setShow({ ...show, providerSettings: false })} />}
            Service Provider
        </h3>

        {
            !show.providerSettings && (
                <div className="content-section">
                    <section className="nav-section">
                        <p>Provider</p>

                        {/* <button
                            disabled={providers.selected.prid && providers.active.prid && providers.active.prid !== providers.selected.prid ? false : true}
                            className={providers.selected.prid && providers.active.prid && providers.active.prid !== providers.selected.prid ? "active-button" : ""}
                            onClick={() => {
                                handleActivateProvider()
                            }}
                        >
                            {
                                operation.pending ? (<ClipLoader size={15} color="white" />) : "Save"
                            }
                        </button> */}
                    </section>

                    <section className="details-section">
                        <div className="input-section">
                            <div className="label">
                                Select Option
                            </div>
                            <div className="selected-option"
                                onClick={() => { 
                                 
                                    setShow({ ...show, selectProvider: !show.selectProvider }) 
                                }}
                            >


                                {
                                    providers.active.vendorName && providers.selected.vendorName ? (providers.selected.vendorName) : !providers.active.vendorName && providers.selected.vendorName ? (providers.selected.vendorName) : providers.active.vendorName && !providers.selected.vendorName ? (providers.active.vendorName) : "Select Option"
                                }

                                <ChevronDown className="icon" />
                            </div>
                        </div>



                        {
                            show.selectProvider && (

                                <div className="provider-list">
                                    {
                                        providers.all?.length ? (
                                            providers.all.map((provider: any) => {
                                                return <div className="item" key={provider.prid}
                                                    onClick={() => {
                                                        setProviders({ ...providers, selected: provider })
                                                        setShow({ ...show, selectProvider: false })

                                                    }}
                                                >
                                                    <div className="title">
                                                        {provider.vendorName}
                                                    </div>
                                                    <div className="actions"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            setProviders({...providers, selected:provider})
                                                            setShow({ ...show, providerSettings: true, selectProvider: false });

                                                        }}
                                                    >
                                                        <Edit className="icon" />
                                                    </div>
                                                </div>
                                            })
                                        ) : null
                                    }


                                    <div className="add-button" onClick={() => { 
                                        setShow({ ...show, createProvider: true })
                                        }}>
                                        <hr />
                                        + Add a New Service Provider
                                    </div>
                                </div>
                            )
                        }


                    </section>

                </div >
            )
        }

        {
            show.providerSettings && (
                <GatewaysSettings providerId={providers.selected.prid} providerName={providers.selected.vendorName} />
            )
        }



        <div className="modals">
            {
                show.createProvider ?
                    <CreateProvider
                        closeModal={() => setShow({ ...show, createProvider: false, providerSettings: false })}
                        closeFunc={() => setShow({ ...show, createProvider: false, providerSettings: true })}
                    />
                    : null
            }
            {
                operation.success ?
                    <Alert
                        closeFunc={() => setOperation((prev) => prev = objectSwitch(operation, "offAll"))
                        }
                        img='/icons/success.png'
                        message='Modified Successfully'
                        miniMessage="You have successfully modified the active provider"
                    />
                    : null
            }


            {
                operation.error ?
                    <Alert
                        closeFunc={() => setOperation((prev) => prev = objectSwitch(operation, "offAll"))}
                        img='/icons/warning.png'
                        message='An error occured!'
                        miniMessage={operation.message}
                    />
                    : null
            }

        </div>


    </div >)
}