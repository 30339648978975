export default function PaymentGatewayExternal() {
    return (
        <div className="payment-gateway-container"
            style={{
                padding: "20px",

                width: "500px",
                margin: " 30px auto",
                borderRadius: "7px",
                display: "flex",
                justifyContent: "center",
                boxShadow: '0px 0px 3px 0px rgba(0, 0, 0, 0.5)'
            }}
        >
            <div className="logo bg-danger"
            style={{height:"40px", width:"60px", backgroundColor:"blue", position:"absolute", top:"-3px"}}
            >
                <img style={{width:"100%", objectFit:"contain"}} src="https://i.im.ge/2023/07/15/5U7llx.WhatsApp-Image-2023-06-29-at-11-46-44-PM.jpg" alt="" />
            </div>
            <div className="content"
                style={{ width: "80%", }}
            >

                <div className="input-section"
                >

                    <div className="label"
                        style={{ fontWeight: "600" }}
                    >cardApiKey</div>
                    <div className="input">
                        <input type="text" style={{ padding: "10px", border: "1px solid grey", width: "100%", borderRadius: "7px", margin: "7px 0px" }} />
                    </div>


                </div>


              
                <div className="input-section"
                style={{margin:"15px 0px"}}
                >

                    <div className="label"
                        style={{ fontWeight: "600" }}
                    >cardKeyRSA</div>
                    <div className="input">
                        <input type="text" style={{ padding: "10px", border: "1px solid grey", width: "100%", borderRadius: "7px", margin: "7px 0px" }} />
                    </div>


                </div>

 

           
              
                <div className="input-section"
                style={{margin:"15px 0px"}}
                >

                    <div className="label"
                        style={{ fontWeight: "600" }}
                    >cardVerificationCode</div>
                    <div className="input">
                        <input type="text" style={{ padding: "10px", border: "1px solid grey", width: "100%", borderRadius: "7px", margin: "7px 0px" }} />
                    </div>


                </div>

 

           
              
                <div className="input-section"
                style={{margin:"15px 0px"}}
                >

                    <div className="label"
                        style={{ fontWeight: "600" }}
                    >cardHashAlg</div>
                    <div className="input">
                        <input type="text" style={{ padding: "10px", border: "1px solid grey", width: "100%", borderRadius: "7px", margin: "7px 0px" }} />
                    </div>


                </div>

 

                
                <center className="button-section">
                    <button
                        style={{
                            padding: "10px 20px",
                            textAlign: "center",
                            backgroundColor: "red", color: "white", borderRadius: "7px"
                        }}
                    >Remove Integration</button>
                </center>


            </div>
        </div>
        )
}