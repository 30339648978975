import React from 'react';
import Index from '../components/kyc';

const KYCPage = () => {
    return(
        <>
            <Index />
        </>
    )
}

export default KYCPage;