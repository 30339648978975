import { ClipLoader } from "react-spinners";
import "../../../styles/modals/invite.scss"
import { Button } from "../../../styles/reusable"
import { InputField, UnderlayText, ModalHeader, ModalWrap } from "../../../styles/reusable/index"
import { ModalChild } from "../../mPos/reusable/style"
import * as FeatherIcon from 'react-feather';
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/types/types";
import { error } from "console";
import { axiosInstance } from "../../../services/AxiosInstance";


interface InviteAdminProps {
    closeFunc: Function,
}




const InviteAdmin: React.FC<InviteAdminProps> = ({ closeFunc }) => {

    const [loading, setLoading] = useState(false)
    const permissionsPayload = useSelector((state: RootState) => state.permissionsPayload)
    const [payload, setPayload] = useState({
        email: "",
        categoryID: permissionsPayload.categoryID,
    })

    const [empty, setEmpty] = useState<boolean>(false)

    function handleValidation() {
        if (payload.email) {
            handleInviteAdmin()
        } else {
            setEmpty(true)
        }

    }


    async function handleInviteAdmin() { 
        try {
            setLoading(true)
            await axiosInstance.post("/super/admin/category/invite", payload).then((resp) => {
                console.log(resp.data)
                setLoading(false)
            })
        } catch (error) {
            setLoading(false)
            console.log(error) 
        }


    }

    return (
        <ModalWrap>
            <ModalChild>
                <ModalHeader>
                    <h3>Invite User by Email</h3>
                    <i><FeatherIcon.X onClick={() => closeFunc()} /></i>
                </ModalHeader>
                <form >
                    <UnderlayText>
                        Please, enter a new email address to Invite New Users
                    </UnderlayText>
                    <InputField>
                        <legend>Email</legend>
                        <input
                            required
                            placeholder={'Enter email address'}
                            type='email'
                            // value={email}
                            onChange={(e) => {
                                setEmpty(false)
                                setPayload({ ...payload, email: e.target.value })
                            }
                            }
                        />

                    </InputField>
                    {
                        empty && !payload.email &&
                        <p className="error">
                            Email is required
                        </p>
                    }
                    <Button
                        width='100%'
                        bg='var(--primary-color)'
                        color='#fff'
                        type='button'
                        disabled={loading}
                        onClick={() => { handleValidation() }}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleValidation()
                            }
                        }}
                        style={loading ? {
                            cursor: 'not-allowed',
                        } : {}
                        }
                    >
                        {/* Invite Admin */}
                        {loading ? <ClipLoader color='#fff' size={18} /> : 'Invite Admin'}
                    </Button>
                </form>
            </ModalChild>
        </ModalWrap>
    )
}


export default InviteAdmin