import React from 'react';
import UserAccess from '../components/access/user';

const UserAccessPage = () => {
    return(
        <>
            <UserAccess />
        </>
    )
}

export default UserAccessPage;