import styled from "styled-components";


interface PropsArgs {
    bg?: string;
    width?: string;
    color?: string;
    border?: string;
    padding?: string;
}

export const Button = styled.button<PropsArgs>`
    background: ${p => p.bg || 'transparent'};
    color: ${p => p.color || 'var(--text-black)'};
    border: ${p => p.border || 'none'};
    padding: ${p => p.padding || '0.7rem 1.5rem'};
    width: ${p => p.width || 'auto'};
    display:flex;
    align-items:Center;
    justify-content:center;
    gap:5px;
`;

export const ErrorWrap = styled.div`
    width:96%;
    padding:0 2%;
    background: #FFEBE8;
    border: 1px dashed #F2000B;
    border-radius: 4px;

    > p {
        font-weight: 500;
        font-size: 13px;
        line-height: 21px;
        letter-spacing: 0.0125em;
        color: #474747;
    }
`