import React from 'react';
import ChangePwd from '../components/changePwd/index';

const ChangePwdPage = () => {
    return(
        <>
            <ChangePwd />
        </>
    )
}

export default ChangePwdPage;