import { createSlice } from "@reduxjs/toolkit";

export interface PermissionsPayloadState {
  email: string;
  categoryID: string;
}
const initialState:PermissionsPayloadState  = {
  email: "",
  categoryID: "",
};

const permissionPayloadSlice = createSlice({
  name: "permissionsPayload",
  initialState,
  reducers: {
    setPermissionsPayload: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const setPermissionsPayload =
  permissionPayloadSlice.actions.setPermissionsPayload;

  export default permissionPayloadSlice.reducer
