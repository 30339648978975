import { Folder } from "react-feather"
import "../../../styles/settings/settingsNavigator.scss"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/types/types"
import { useEffect } from "react"
import { setToggleSettings } from "../../../redux/slices/toggleSettingsSlice"
import classnames from "classnames"

export default function SettingsNavigator() {

    const toggleSettings = useSelector((state: RootState) => state.toggleSettings)
    const dispatch = useDispatch()
    // const itemClassNames = classnames("item", {
    //     'active-item': (toggleSettings).team ===true,
    // }) 
    // useEffect(() => {
    //  }, [toggleSettings]); 

    function handleToggleSettings(property: string) {
        const newObject: Record<string, boolean | string> = { ...toggleSettings };
        const objectKeys = Object.keys(newObject);

        objectKeys.forEach((key: string) => {
            newObject[key] = false;
        });

        newObject[property] = true;

        dispatch(setToggleSettings(newObject));
    }


    return (
        <div className="settings-navigator-container">

            <div className={toggleSettings.general?"item active-item":"item"}
                onClick={() => { handleToggleSettings("general") }}
            >
                <div className="icon-section">
                    <Folder className="icon" />
                </div>
                <div className="text">
                    General Settings
                </div>
            </div>


            <div className={toggleSettings.team?"item active-item":"item"}
                onClick={() => { handleToggleSettings("team") }}

            >
                <div className="icon-section">
                    <Folder className="icon" />
                </div>
                <div className="text">
                    Team
                </div>
            </div>


            <div className={toggleSettings.permissions?"item active-item":"item"}
                onClick={() => { handleToggleSettings("permissions") }}

            >
                <div className="icon-section">
                    <Folder className="icon" />
                </div>
                <div className="text">
                    Permissions
                </div>
            </div>


            <div className={toggleSettings.paymentMethod?"item active-item":"item"}
                onClick={() => { handleToggleSettings("paymentMethod") }}

            >
                <div className="icon-section">
                    <Folder className="icon" />
                </div>
                <div className="text">
                    Payment Method
                </div>
            </div>


            <div className={toggleSettings.controlPanel?"item active-item":"item"}
                onClick={() => { handleToggleSettings("controlPanel") }}

            >
                <div className="icon-section">
                    <Folder className="icon" />
                </div>
                <div className="text">
                    Control Panel
                </div>
            </div>



        </div>
    )
}