import React from 'react';
import SettingsMain from '../components/singleUser/settings';
import ControlPanelMain from '../components/singleUser/settings/control-panel';

const ControlPanelPage = () => {
    return(
        <>
            <ControlPanelMain />
        </>
    )
}

export default ControlPanelPage;