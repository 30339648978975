import React from 'react';
import ProfileIndex from '../components/profile';

const ProfilePage = () => {
    return(
        <>
            <ProfileIndex />
        </>
    )
}

export default ProfilePage;