import { axiosInstance } from "../AxiosInstance";
import { ErrorType } from "../UpdateAccess";


interface responseProp {
    // data:any,
    [key:string]:string
}
export async function getAllBusinessModels(){

    let response:{[key:string]:responseProp[]} | any ={};
    let error:ErrorType ={};

    try {
        await axiosInstance.get("/super/admin/business/all").then((resp)=>{
            response = resp.data
        })
        
    } catch (err:any) {
        error = err
    }

return {response, error}
}