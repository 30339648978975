import React, { useEffect, useState } from "react"
import { getAllBusinessModels } from "../../../services/accounts/GetAllBusinessModels"
import "../../../styles/accounts/gatewaySettings.scss"
import { updateProvidersModel } from "../../../services/accounts/UpdateProvidersModel";
import { ClipLoader } from "react-spinners";
import Alert from "../../mPos/reusable/alert";

interface gatewayProps {
    providerId: string | number,
    providerName: string,
}
const GatewaysSettings = ({ providerId, providerName }: gatewayProps) => {

    const [businessModels, setBusinessModels] = useState([])
    const [modelPayload, setModelPayload] = useState({
        gatewayPayIn: {
            // providerID: providerId,
            feePercent: "",
            cap: "",
            mongoroFeePercent: "",
            mongoroCap: "",
        },
        posPayIn: {
            // providerID: providerId,
            feePercent: "",
            cap: "",
            mongoroFeePercent: "",
            mongoroCap: "",
        },
        posPayOut: {
            // providerID: providerId,
            fee0to5000: "",
            fee5000to50000: "",
            fee50000: "",
            mongoroFee100to1000: "",
            mongoroFee1000to3000: "",
            mongoroFee3000to5000: "",
            mongoroFee5000to10000: "",
            mongoroFee10000to20000: "",
            mongoroFee20000to50000: "",
            mongoroFee50000to100000: "",
            mongoroFee100000: "",
        },
        collection: {
            // providerID: providerId,
            feeTransfer: "",
            feeUssd: "",
            mongoroFeeUssd: "",
            mongoroFeeTransfer: "",
            mongoroCap: "",
            cap: "",
        },
        disbursement: {
            // providerID: providerId,
            fee0to5000: "",
            fee5000to50000: "",
            fee50000: "",
            mongoroFee100to1000: "",
            mongoroFee1000to3000: "",
            mongoroFee3000to5000: "",
            mongoroFee5000to10000: "",
            mongoroFee10000to20000: "",
            mongoroFee20000to50000: "",
            mongoroFee50000to100000: "",
            mongoroFee100000: "",
        },
    });

    const [inProcess, setInProcess] = useState(false)
    const [status, setStatus] = useState({
        success: false,
        error: ""
    })

    const [isEmpty, setIsEmpty] = useState({
        gatewayPayIn: false,
        posPayIn: false,
        posPayOut: false,
        collection: false,
        disbursement: false,

    })


    useEffect(() => {
        handleAllBusinessModels()
    }, [])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setIsEmpty({ ...isEmpty, gatewayPayIn: false, posPayIn: false, posPayOut: false, collection: false, disbursement: false })


        const { name, value } = event.target;
        const [category, property] = name.split(".");

        setModelPayload((prevModelPayload) => ({
            ...prevModelPayload,
            [category]: {
                ...(prevModelPayload as any)[category],
                [property]: value,
            },
        }));
    };


    const handleValidate = <T extends keyof typeof modelPayload>(key: T) => {
        const payloadObject = modelPayload[key];
        let hasEmptyValue = false;

        for (const prop in payloadObject) {
            if (payloadObject[prop as keyof typeof payloadObject] === "") {
                hasEmptyValue = true;
                break;
            }
        }

        setIsEmpty((prevIsEmpty) => ({ ...prevIsEmpty, [key]: hasEmptyValue }));

        return isEmpty;
    };


    async function handleAllBusinessModels() {

        try {
            const { response, error } = await getAllBusinessModels()
            if (error?.response) {
                // console.log(error)
            } else if (response) {
                setBusinessModels(response.data)
                // console.log(response)
            }

        } catch (error) {
            // console.log(error)

        }

    }




    function filterModel(provider: any, modeltype: any) {

        const selectedModel = businessModels.filter((model: any) => {
            return parseInt(model.providerID) === parseInt(provider) && parseInt(model.type) === parseInt(modeltype);
        })
    }


    async function handleUpdateModel(type: string, payload: any) {
        try {
            setInProcess(true)
            const { response, error } = await updateProvidersModel(type, { ...payload, providerID: providerId })
            // console.log(response)

            if (error?.response) {
                setInProcess(false)
                setStatus({ ...status, error: error.response.data.message })
                // console.log(error)
            } else if (response) {
                setStatus({ ...status, success: true })
                setInProcess(false)
            }
        } catch (error) {
            setInProcess(false)
            // console.log(error)
        }
    }


    const validateObject = (obj: { [key: string]: string }) => {
        for (const key in obj) {
            if (!obj[key]) {
                return false;
            }
        }
        return true;
    };

    const handleButtonClick = async (objectKey: keyof typeof modelPayload) => {
        const payloadObject = modelPayload[objectKey];
        const isObjectValid = validateObject(payloadObject);

        if (isObjectValid) {
            handleUpdateModel(objectKey, payloadObject); 
        } else { 
            setIsEmpty({ ...isEmpty, [objectKey]: true });
        }
    };



    return (
        <div className="gateways-settings-container">

            <div className="gateway-content">
                Gateway Pay-in
                <section className="gateway-row">
                    <div className="external-gateway">
                        <p className="heading">Gateway 1</p>

                        <div className="input-section">
                            <div className="label">
                                Service Provider
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="Provider"
                                    defaultValue={providerName}
                                    disabled
                                />
                            </div>

                        </div>


                        <div className="input-section">
                            <div className="label">
                                Fee (in %)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="gatewayPayIn.feePercent"
                                    value={modelPayload.gatewayPayIn.feePercent}
                                    onChange={handleChange}
                                />
                            </div>

                        </div>


                        <div className="input-section">
                            <div className="label">
                                Cap (in ₦)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="gatewayPayIn.cap"
                                    value={modelPayload.gatewayPayIn.cap}
                                    onChange={handleChange}
                                />
                            </div>




                        </div>

                    </div>

                    <div className="mongoro-gateway">

                        <p className="heading">Mongoro</p>


                        <div className="input-section">
                            <div className="label">
                                Fee (in %)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="gatewayPayIn.mongoroFeePercent"
                                    value={modelPayload.gatewayPayIn.mongoroFeePercent}
                                    onChange={handleChange}
                                />
                            </div>

                        </div>
                        <div className="input-section">
                            <div className="label">
                                Cap (in ₦)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="gatewayPayIn.mongoroCap"
                                    value={modelPayload.gatewayPayIn.mongoroCap}
                                    onChange={handleChange}
                                />
                            </div>


                        </div>

                    </div>
                </section>
                <center>
                    {
                        isEmpty.gatewayPayIn && <div className="error">All fields are required</div>
                    }
                </center>

                <div className="button-section">
                    <button className="save-btn"
                        onClick={() => {

                            handleButtonClick("gatewayPayIn")
                            // if (modelPayload.gatewayPayIn.cap &&
                            //     modelPayload.gatewayPayIn.feePercent &&
                            //     modelPayload.gatewayPayIn.mongoroCap &&
                            //     modelPayload.gatewayPayIn.mongoroFeePercent
                            // ) {

                            //     handleUpdateModel("gateway", modelPayload.gatewayPayIn)
                            // } else {
                            //     setIsEmpty({ ...isEmpty, gatewayPayIn: true })
                            // }
                        }}
                    >
                        {
                            inProcess ? (
                                <ClipLoader size={20} color="white" />
                            ) : ("Save")
                        }

                    </button>

                </div>
            </div>



            {/* POS PAY IN */}
            <div className="gateway-content">
                POS Pay-in

                <section className="gateway-row">
                    <div className="external-gateway">
                        {/* <p className="heading">Gateway 1</p> */}

                        <div className="input-section">
                            <div className="label">
                                Service Provider
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="provider"
                                    defaultValue={providerName}
                                    disabled
                                />
                            </div>

                        </div>




                        <div className="input-section">
                            <div className="label">
                                Fee (in %)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayIn.feePercent"
                                    value={modelPayload.posPayIn.feePercent}
                                    onChange={handleChange}
                                />
                            </div>

                        </div>


                        <div className="input-section">
                            <div className="label">
                                Cap (in ₦)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayIn.cap"
                                    value={modelPayload.posPayIn.cap}
                                    onChange={handleChange}
                                />
                            </div>




                        </div>



                    </div>

                    <div className="mongoro-gateway">

                        <p className="heading">Mongoro</p>


                        <div className="input-section">
                            <div className="label">
                                Fee (in %)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayIn.mongoroFeePercent"
                                    value={modelPayload.posPayIn.mongoroFeePercent}
                                    onChange={handleChange}
                                />
                            </div>

                        </div>


                        <div className="input-section">
                            <div className="label">
                                Cap (in ₦)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayIn.mongoroCap"
                                    value={modelPayload.posPayIn.mongoroCap}
                                    onChange={handleChange}
                                />
                            </div>


                        </div>


                    </div>
                </section>


                <center>
                    {
                        isEmpty.posPayIn && <div className="error">
                            All fields are required
                        </div>
                    }
                </center>


                <div className="button-section">
                    <button className="save-btn"
                        onClick={() => {
                            handleButtonClick("posPayIn")
                        }}
                    >
                        {
                            inProcess ? (
                                <ClipLoader size={20} color="white" />
                            ) : ("Save")
                        }

                    </button>

                </div>
            </div>




            {/* POS PAYOUT */}
            <div className="gateway-content">
                POS Pay-Out

                <section className="gateway-row">
                    <div className="external-gateway">
                        {/* <p className="heading">Gateway 1</p> */}

                        <div className="input-section">
                            <div className="label">
                                Service Provider
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="provider"
                                    defaultValue={providerName}
                                    disabled
                                />
                            </div>

                        </div>




                        <div className="input-section">
                            <div className="label">
                                Fee (0 - ₦5, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayOut.fee0to5000"
                                    value={modelPayload.posPayOut.fee0to5000}
                                    onChange={handleChange}
                                />
                            </div>

                        </div>


                        <div className="input-section">
                            <div className="label">
                                Fee (₦5, 000 - ₦50, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayOut.fee5000to50000"
                                    value={modelPayload.posPayOut.fee5000to50000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee ( &gt; ₦50, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayOut.fee50000"
                                    value={modelPayload.posPayOut.fee50000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>


                    </div>



                    <div className="mongoro-gateway">

                        <p className="heading">Mongoro</p>




                        <div className="input-section">
                            <div className="label">
                                Fee (₦100 - ₦1, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayOut.mongoroFee100to1000"
                                    value={modelPayload.posPayOut.mongoroFee100to1000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (₦1000 - ₦3, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayOut.mongoroFee1000to3000"
                                    value={modelPayload.posPayOut.mongoroFee1000to3000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (₦3000 - ₦5, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayOut.mongoroFee3000to5000"
                                    value={modelPayload.posPayOut.mongoroFee3000to5000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (₦5000 - ₦10, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayOut.mongoroFee5000to10000"
                                    value={modelPayload.posPayOut.mongoroFee5000to10000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (₦10, 000 - ₦20, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayOut.mongoroFee10000to20000"
                                    value={modelPayload.posPayOut.mongoroFee10000to20000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (₦20, 000 - ₦50, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayOut.mongoroFee20000to50000"
                                    value={modelPayload.posPayOut.mongoroFee20000to50000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (₦50, 000 - ₦100, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayOut.mongoroFee50000to100000"
                                    value={modelPayload.posPayOut.mongoroFee50000to100000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee ( &gt; ₦100, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="posPayOut.mongoroFee100000"
                                    value={modelPayload.posPayOut.mongoroFee100000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>


                    </div>
                </section>

                <center>
                    {
                        isEmpty.posPayOut && <div className="error">
                            All fields are required
                        </div>
                    }
                </center>
                <div className="button-section">
                    <button className="save-btn"
                        onClick={() => {
                            handleButtonClick("posPayOut")
                            // handleUpdateModel("posPayout", modelPayload.posPayOut)
                        }}
                    >
                        {
                            inProcess ? (
                                <ClipLoader size={20} color="white" />
                            ) : ("Save")
                        }

                    </button>
                </div>
            </div>


            {/* COLLECTION */}
            <div className="gateway-content">
                Collection

                <section className="gateway-row">
                    <div className="external-gateway">
                        {/* <p className="heading">Gateway 1</p> */}

                        <div className="input-section">
                            <div className="label">
                                Service Provider
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="provider"
                                    defaultValue={providerName}
                                    disabled
                                />
                            </div>

                        </div>




                        <div className="input-section">
                            <div className="label">
                                Fee ( Bank Transfer in %)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="collection.feeTransfer"
                                    value={modelPayload.collection.feeTransfer}
                                    onChange={handleChange}
                                />
                            </div>

                        </div>


                        <div className="input-section">
                            <div className="label">
                                Cap (in ₦)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="collection.cap"
                                    value={modelPayload.collection.cap}
                                    onChange={handleChange}
                                />
                            </div>




                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (USSD)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="collection.feeUssd"
                                    value={modelPayload.collection.feeUssd}
                                    onChange={handleChange}
                                />
                            </div>

                        </div>



                    </div>

                    <div className="mongoro-gateway">

                        <p className="heading">Mongoro</p>


                        <div className="input-section">
                            <div className="label">
                                Fee (Bank Transfer in %)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="collection.mongoroFeeTransfer"
                                    value={modelPayload.collection.mongoroFeeTransfer}
                                    onChange={handleChange}
                                />
                            </div>

                        </div>


                        <div className="input-section">
                            <div className="label">
                                Cap
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="collection.mongoroCap"
                                    value={modelPayload.collection.mongoroCap}
                                    onChange={handleChange}
                                />
                            </div>


                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (USSD)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="collection.mongoroFeeUssd"
                                    value={modelPayload.collection.mongoroFeeUssd}
                                    onChange={handleChange}
                                />
                            </div>

                        </div>


                    </div>
                </section>
                <center>
                    {
                        isEmpty.collection && <div className="error">
                            All fields are required
                        </div>
                    }
                </center>

                <div className="button-section">
                    <button className="save-btn"
                        onClick={() => {
                            handleButtonClick("collection")
                            // handleUpdateModel("collection", modelPayload.collection)
                        }}
                    >
                        {
                            inProcess ? (
                                <ClipLoader size={20} color="white" />
                            ) : ("Save")
                        }

                    </button>

                </div>
            </div>


            {/*DISBURSEMENT */}
            <div className="gateway-content">
                Disbursement

                <section className="gateway-row">
                    <div className="external-gateway">
                        {/* <p className="heading">Gateway 1</p> */}

                        <div className="input-section">
                            <div className="label">
                                Service Provider
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="provider"
                                    defaultValue={providerName}
                                    disabled
                                />
                            </div>

                        </div>




                        <div className="input-section">
                            <div className="label">
                                Fee (0 - ₦5, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="disbursement.fee0to5000"
                                    value={modelPayload.disbursement.fee0to5000}
                                    onChange={handleChange}
                                />
                            </div>

                        </div>


                        <div className="input-section">
                            <div className="label">
                                Fee (₦5, 000 - ₦50, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="disbursement.fee5000to50000"
                                    value={modelPayload.disbursement.fee5000to50000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee ( &gt; ₦50, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="disbursement.fee50000"
                                    value={modelPayload.disbursement.fee50000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>


                    </div>



                    <div className="mongoro-gateway">

                        <p className="heading">Mongoro</p>




                        <div className="input-section">
                            <div className="label">
                                Fee (₦100 - ₦1, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="disbursement.mongoroFee100to1000"
                                    value={modelPayload.disbursement.mongoroFee100to1000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (₦1000 - ₦3, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="disbursement.mongoroFee1000to3000"
                                    value={modelPayload.disbursement.mongoroFee1000to3000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (₦3000 - ₦5, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="disbursement.mongoroFee3000to5000"
                                    value={modelPayload.disbursement.mongoroFee3000to5000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (₦5000 - ₦10, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="disbursement.mongoroFee5000to10000"
                                    value={modelPayload.disbursement.mongoroFee5000to10000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (₦10, 000 - ₦20, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="disbursement.mongoroFee10000to20000"
                                    value={modelPayload.disbursement.mongoroFee10000to20000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (₦20, 000 - ₦50, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="disbursement.mongoroFee20000to50000"
                                    value={modelPayload.disbursement.mongoroFee20000to50000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee (₦50, 000 - ₦100, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="disbursement.mongoroFee50000to100000"
                                    value={modelPayload.disbursement.mongoroFee50000to100000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>

                        <div className="input-section">
                            <div className="label">
                                Fee ( &gt; ₦100, 000)
                            </div>
                            <div className="input">
                                <input type="text"
                                    placeholder="00.00"
                                    name="disbursement.mongoroFee100000"
                                    value={modelPayload.disbursement.mongoroFee100000}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>


                    </div>
                </section>
                <center>
                    {
                        isEmpty.disbursement && <div className="error">
                            All fields are required
                        </div>
                    }
                </center>

                <div className="button-section">
                    <button className="save-btn"
                        onClick={() => {
                            handleButtonClick("disbursement")
                            // handleUpdateModel("disbursement", modelPayload.disbursement)
                        }}
                    >
                        {
                            inProcess ? (
                                <ClipLoader size={20} color="white" />
                            ) : ("Save")
                        }

                    </button>

                </div>
            </div>

            {
                status.success ?
                    <Alert
                        closeFunc={() => { setStatus({ ...status, success: false }) }}
                        img='/icons/success.png'
                        message='Modification Successful'
                        miniMessage="Provider Settings updated successfully"
                    />
                    : null
            }

            {
                status.error ?
                    <Alert
                        closeFunc={() => { setStatus({ ...status, error: "" }) }}
                        img='/icons/warning.png'
                        message='An error occured!'
                        miniMessage={status.error}
                    />
                    : null
            }

        </div>)
}



export default GatewaysSettings;