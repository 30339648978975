import styled from 'styled-components'

export const ChatMainFlex = styled.div`
    display:flex;
    align-items:flex-start;
    gap:40px;
`

export const ChatSideBar = styled.div`
    width: 25%;
    padding: 10px 15px;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 14px;
`

export const LineFlexCard = styled.div`
    display:flex;
    align-items:Center;
    justify-content:space-between;

    > div {
        width:90%;
        display:flex;
        align-items:Center;
        gap:10px;
    }
`

export const ActionWrap = styled.div`
    width:95%;
    margin: 1rem auto;
    padding:10px 10px;
    background: #FFFFFF;
    border: 1px solid #EEF0F7;
    display:flex;
    align-items:Center;
    justify-content:space-between;
`

export const MiniBtn = styled.button`
    width: auto;
    display:flex;
    align-items:Center;
    justify-content:Center;
    background: #F3F6F8;
    border: 0.5px solid #FFAB01;
    border-radius: 16px;
    padding:10px 1rem;
    font-size:12px;
    color: #ffab01;
`

export const ChatAvatar = styled.img`
    width:50px;
    height:50px;
    border-radius: 14px;
`

export const ChatMainWrap = styled.div`
    width:70%;
    background: #fff;
    padding:2px 10px;
    position:relative;

    :before {
        content: '';
        position:absolute;
        width:100%;
        height:100%;
        
    }
`;

export const ChatHeader = styled.div`
    width:95%;
    padding:10px 2.5% ;
    background: #FFFFFF;
    border-radius: 14px;
    z-index:10000;
`

export const ChatHeadernav = styled.section`
    display:flex;
    align-items:center;
    justify-content:flex-end;
    gap:20px;
`;

export const MessageWrap = styled.div`
    height:20rem;
    overflow-y:auto;
    padding: 10px 0;
    background: url('./images/chat-bg.png');
    background-size:cover;
    background-repeat:none;
    background-position:center;
`

export const LeftMessage = styled.div`
    display:flex;
    align-items:flex-start;
    gap:10px;
    max-width:80%;
    margin: 1rem 0 0.5rem 0;

    > div {
        display:inline-block;
        padding:10px;
        background: #F5F5F5;
        border-radius: 14px 14px 14px 0px;
    }
`

export const RightMessage = styled.div`
    display:flex;
    align-items:flex-start;
    justify-content:flex-end;
    gap:10px;
    margin: 1rem 0 0.5rem 0;

    > div {
        display:inline-block;
        max-width:50%;
        padding:10px;
        background: #373708;
        border-radius: 14px 14px 14px 0px;
    }
`

export const ChatInputArea = styled.div`
    position:sticky;
    bottom:0;
    left:0;
    background:#fff;
    padding:10px;
`

export const ChatTextArea = styled.textarea`
    width:95%;
    padding:10px 2.5%;
    resize:none;
    background: #FFFFFF;
    border: 2px solid #FFAB01;
    border-radius: 14px;
`

export const ChatSendArea = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:20px 0;

    > div {
        display:flex;
        align-items:Center;
        gap:15px;
    }
`

export const SendButton = styled.button`
    width:auto;
    padding:10px 20px;
    display:flex;
    align-items:Center;
    gap:10px;
    background: #FFAB01;
    color: #fff;
`