import React, { useState } from "react";
import {
  ModalWrap,
  ModalChild,
  ModalHeader,
  InputField,
} from "../../styles/reusable/index";
import * as FeatherIcon from "react-feather";
import { Button } from "../../styles/reusable";
import { MiniBtn } from "../../styles/chat";
import * as Icon from "iconsax-react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import Error from "../mPos/reusable/error";

interface PropArgs {
  closeFunc: any;
}

const CreateBroadcast = ({ closeFunc }: PropArgs) => {
  const [notificationType, setNotificationType] = useState("");
  const [notificationBody, setNotificationBody] = useState("");
  const [notificationSubject, setNotificationSubject] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const sendOutNotifications = async () => {
    try {
      const loggedAdmin = localStorage.getItem("tks") || "{}";
      setIsLoading(true);
      if (notificationType === "email") {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/notification/general`,
          {
            body: notificationBody,
            subject: notificationSubject,
          },
          {
            headers: {
              "x-token": `${loggedAdmin}`,
            },
          }
        );
      } else {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/admin/push/notification`,
          {
            title: notificationBody,
            body: notificationSubject,
          },
          {
            headers: {
              "x-token": `${loggedAdmin}`,
            },
          }
        );
      }
      closeFunc();
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      setError(error.response.data.message);
      setTimeout(() => {
        setError("");
      }, 4000);
    }
  };

  return (
    <>
      <ModalWrap>
        <ModalChild>
          <ModalHeader>
            <h3>Send Broadcast</h3>
            <i>
              <FeatherIcon.X onClick={() => closeFunc()} />
            </i>
          </ModalHeader>

          <InputField>
            <legend>Message Type</legend>
            <select
              value={notificationType}
              onChange={(e) => setNotificationType(e.target.value)}
            >
              <option value="">Select Notification Type</option>
              <option value="in-app">In-App Notification</option>
              <option value="email">Email Notification</option>
            </select>
          </InputField>
          {/* <InputField>
            <legend>Recipients</legend>
            <select>
              <option>Daniel Adewale</option>
            </select>
          </InputField> */}
          <InputField>
            <legend>Subject</legend>
            <input
              placeholder={"Enter Subject"}
              value={notificationSubject}
              onChange={(e) => setNotificationSubject(e.target.value)}
            />
          </InputField>
          <InputField>
            <legend>Message</legend>
            <textarea
              placeholder="Message"
              value={notificationBody}
              onChange={(e) => setNotificationBody(e.target.value)}
            ></textarea>
          </InputField>
          {/* <MiniBtn>
            <Icon.Microphone />
            Attach File
          </MiniBtn> */}
          <Button
            onClick={sendOutNotifications}
            width="100%"
            bg="var(--primary-color)"
            color="#fff"
            disabled={
              notificationBody.trim() === "" ||
              notificationSubject.trim() === "" ||
              notificationType.trim() === ""
            }
            style={{ marginBottom: "8px" }}
          >
            {isLoading ? <ClipLoader color="#fff" size={17} /> : "Send"}
            {error !== "" ? <Error message={error} /> : null}
          </Button>
        </ModalChild>
      </ModalWrap>
    </>
  );
};

export default CreateBroadcast;
