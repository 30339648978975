import { axiosInstance } from "../AxiosInstance";
import { ErrorType } from "../UpdateAccess";

interface responseProp {
  // data:any,
  [key: string]: string | any;
}
export async function getGatewayDetails(type:number|string) {
  let response: { [key: string]: responseProp[] } | any = {};
  let error: ErrorType = {};

  try {
    await axiosInstance.get(`/super/admin/provider/business/${type}`).then((resp) => {
      response = resp.data;
    });
  } catch (err: any) {
    error = err;
  }

  return { response, error };
}
