import React from "react";
import { ModalWrap, ModalChild, ModalHeader, InputField, UnderlayText } from "../../styles/reusable/index";
import * as FeatherIcon from 'react-feather';
import { Button } from "../../styles/reusable";
import { ClipLoader } from 'react-spinners';

interface PropArgs {
    closeFunc: any;
    email?: string;
    setEmail?: any;
    action?: any;
    category?: string;
    categoryList?: any;
    setCategory?: any;
    onProcess?: boolean;
}

const InviteUser = ({
    closeFunc,
    email,
    setEmail,
    category,
    categoryList,
    setCategory,
    action,
    onProcess
} : PropArgs) => {
    return(
        <>
            <ModalWrap>
                <ModalChild>
                    <ModalHeader>
                        <h3>Invite User by Email</h3>
                        <i><FeatherIcon.X onClick={() => closeFunc()} /></i>
                    </ModalHeader>
                    <form onSubmit={(e) => action(e)}>
                        <UnderlayText>
                            Please, enter a new email address to Invite New Users
                        </UnderlayText>
                        <InputField>
                            <legend>Email</legend>
                            <input 
                                required
                                placeholder={'Enter email address'}
                                type='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </InputField>
                        <InputField>
                            <legend>Category</legend>
                            <select
                                required
                                value={category}
                                placeholder={'Select Category'}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                {
                                    categoryList && categoryList.length > 0 ?
                                        categoryList.map((item:any, index:number) => (
                                            <option
                                                key={index}
                                            >{item.category}</option>
                                        ))
                                    : null
                                }
                            </select>
                        </InputField>
                        <Button
                            width='100%'
                            bg='var(--primary-color)'
                            color='#fff'
                            type='submit'
                            disabled={onProcess}
                            style={ onProcess ? {
                                cursor: 'not-allowed',
                            }: {}}
                        >
                            {onProcess ? <ClipLoader color='#fff' size={18} /> : 'Invite User'}
                        </Button>
                    </form>
                </ModalChild>
            </ModalWrap>
        </>
    )
}

export default InviteUser;
