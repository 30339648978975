


export function objectSwitch(object: { [key: string]: any }, property: string) {
    const objClone = { ...object };

  if (property !== "offAll") { 
    Object.keys(objClone).map((key) => {
      object[key] = false;
    });

    object[property] = true;
  }

  if (property === "offAll") {
    
    Object.keys(objClone).map((key) => {
      object[key] = false;
    });
  }


  return objClone;
}
