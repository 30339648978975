import { Checkbox } from "../components/mPos/reusable/Checkbox";
import "../styles/settings/permissions.scss"
import "../styles/reusable/checkbox.scss"
import "../styles/reusable/toggleButton.scss"
import { useSelector } from "react-redux";
import { RootState } from "../redux/types/types";
import { PieChart } from "react-feather";
import { useEffect, useState } from "react";
import InviteAdmin from "../components/access/modals/InviteAdmin";
import { updateAccess } from "../services/UpdateAccess";
import { ClipLoader } from "react-spinners";
import Alert from "../components/mPos/reusable/alert";
import { axiosInstance } from "../services/AxiosInstance";
import { More } from "iconsax-react";
import { AccountPopUp } from "../styles/reusable/header";
import { MoreHorizSharp } from "@mui/icons-material";
import AccessPrompt from "../components/access/modals/AccessPrompt";
export default function Permissions() {
  const toggleSettings = useSelector((state: RootState) => state.toggleSettings)
  const [inviteAdmin, setinviteAdmin] = useState(false)
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [createSuccess, setCreateSuccess] = useState<boolean>(false)
  const [createError, setCreateError] = useState<string>("")
  const [permissions, setPermissions] = useState<PermissionsType[]>([])



  const [showOptions, setShowOptions] = useState(0)
  const [updateControl, setUpdateControl] = useState({
    action: false,
    type: "",
    permissionId: "",
    role: "",
    access: ""
  })


  interface PermissionsType {
    [key: string]: string | any,
  }



  useEffect(() => {
    handleGetCategoriesPermissions()
  }, [])

  async function handleGetCategoriesPermissions() {
    const params = new URLSearchParams(window.location.search);
    const id = params.get('id');

    try {
      await axiosInstance.get(`/super/admin/category/${id}`).then((resp) => {
        setPermissions(resp.data.data.permissions)
      })
    } catch (error) {
      // console.log(error)

    }
  }



  function handleClose() {
    setinviteAdmin(false)
  }


  async function handleSubmit(action: string, payload: any) {
    setInProgress(true)
    const { response, error } = await updateAccess(action, payload)
    if (error) {
      setInProgress(false)

      setCreateError(error.response?.data.message)
      console.log(error)
    } else {
      setInProgress(false)
      setCreateSuccess(true)
      console.log(response)
    }

  }


  async function handleModifyAccess(perid: string | number, type: string) {
    const params = new URLSearchParams(window.location.search);
    const CatId = params.get('id');

    if (type === "grantAccess") {
      setInProgress(true)
      const { response, error } = await updateAccess("activate", { permissionID: perid, categoryID: CatId })
      if (error.response) {
        setInProgress(false)
        setUpdateControl({ ...updateControl, action: false })
        setCreateError(error.response?.data.message)
      } else {
        setInProgress(false)
        setUpdateControl({ ...updateControl, action: false })
        setCreateSuccess(true)
        handleGetCategoriesPermissions()
      }

    } else if (type === "revokeAccess") {


      setInProgress(true)
      const { response, error } = await updateAccess("deactivate", { permissionID: perid, categoryID: CatId })
      if (error.response) {
        setInProgress(false)
        setUpdateControl({ ...updateControl, action: false })
        setCreateError(error.response?.data.message)
      } else {
        setInProgress(false)
        setUpdateControl({ ...updateControl, action: false })
        setCreateSuccess(true)
        handleGetCategoriesPermissions()
      }
    }
  }






  return (
    <div className="permissions-container" onClick={() => setShowOptions(0)} >


      {
        inviteAdmin && <InviteAdmin closeFunc={handleClose} />
      }


      <header className="header">
        <div className="title-section">
          <h4>{toggleSettings.role}</h4>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi cupiditate amet architecto tempora modi. Nisi?</p>
          {/* <button>Edit</button> */}
        </div>
        <div className="team-section">
          <h4>Team members with this role</h4>

          <div className="invite-section">
            <div className="user-acronym">
              AA
            </div>
            <div className="invite-btn" onClick={() => setinviteAdmin(true)} >
              Invite
            </div>
          </div>
        </div>
      </header>


      <section >
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th className="permission-td">PERMISSION</th>
              {/* <th>Activate Access</th>
              <th>Deactivate Access</th> */}
              <th>Access Status</th>
              <th>Manage Access</th>
            </tr>
          </thead>


          {
            permissions.length ? (
              <tbody>
                {
                  permissions.length && permissions.map((data, i) => {
                    return <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{data.permission}</td>
                      <td>
                        <label className="checkbox-container center">
                          <input
                            type="checkbox"
                            checked={data.status}
                            disabled
                            name="dashboard"
                          />
                          <span className="checkmark"></span>

                        </label>
                      </td>
                      <td>

                        <label className="more">
                          <MoreHorizSharp className="icon" onClick={(e) => {
                            e.stopPropagation()
                            setShowOptions(data.perid)

                          }} />



                          {
                            showOptions === data.perid ? (
                              <div className="options-container">

                                {
                                  data.status ? (<div className="deactivate action"
                                    onClick={(e) => {
                                      setUpdateControl({ ...updateControl, action: true, permissionId: data.perid, type: "revokeAccess", access: data.permission })
                                    }}
                                  >Revoke Access</div>) : (
                                    <div className="activate action"
                                      onClick={(e) => {
                                        setUpdateControl({ ...updateControl, action: true, permissionId: data.perid, type: "grantAccess", access: data.permission })
                                      }}

                                    >Grant Access</div>
                                  )
                                }
                              </div>
                            ) : null
                          }




                        </label>
                      </td>

                    </tr>

                  })
                }



              </tbody>
            ) : null
          }

        </table>

      </section>


      {
        updateControl.action && (
          <AccessPrompt
            message={
              updateControl.type === "revokeAccess" ?
               `This action will revoke all ${updateControl.access} access given to ${toggleSettings.role}. Proceed?`
              :
              `This action will grant  all ${toggleSettings.role} access to ${updateControl.access}. Proceed?`
            }
            heading={"Acess Management WARNING!!!"}
            handleSubmit={() => handleModifyAccess(updateControl.permissionId, updateControl.type)}
            closeFunc={() => setUpdateControl({ ...updateControl, action: false })}
            loading={inProgress}
          />

        )
      }


{/* SUCCESS ALERT FOR ACCESS MODIFICATION */}
      {
        createSuccess && (
          <Alert
            closeFunc={() => setCreateSuccess(false)}
            img='/icons/success.png'
            message='Access modified successfully!'
            miniMessage=''
          />

        )
      }

      {
        createError && (
          <Alert
            closeFunc={() => setCreateError('')}
            img='/icons/warning.png'
            message='An error occured!'
            miniMessage={createError}
          />
        )
      }
    </div>
  );
}
