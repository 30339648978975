import React from 'react';
import { CardGridWrap, MainCard, OverviewCardWrap } from '../../styles/dashboard/cardStyle';
import * as Icon from 'iconsax-react';
import moment from 'moment';
import CommaNumber from 'comma-number';

interface PropsArgs {
    props: any;
    savingsProps: any;
    transactionProps: any;
    depositProps: any;
    withdrawProps: any;
}

const CardSection = ({props, savingsProps, transactionProps, depositProps, withdrawProps} : PropsArgs) => {
    const date = new Date();
    return(
        <>
            <CardGridWrap>
                <MainCard bgImage={`url('images/card1.png')`}>
                    <h3>Account Status:  
                        <b> REGULAR NGN</b>
                    </h3>
                    <h1>&#8358;{savingsProps.total ? CommaNumber(savingsProps.total) : ''}</h1>
                    <p>
                        Updated At:  
                        <> {moment(date).format('LL')} </>
                    </p>
                </MainCard>
                <OverviewCardWrap>
                    <div>
                        <h3>Total Deposit</h3>
                        <h1>&#8358;{depositProps.total ? CommaNumber(depositProps.total) : ''}</h1>
                        <p>Today | This Week | This month</p>
                        <i><Icon.EmptyWallet /></i>
                    </div>
                    <div>
                        <h3>Total POS Deposit</h3>
                        <h1>&#8358;0.00</h1>
                        <p>Today | This Week | This month</p>
                        <i><Icon.EmptyWallet /></i>
                    </div>
                    <div>
                        <h3>Total Withdrawal</h3>
                        <h1>&#8358;{withdrawProps.total ? CommaNumber(withdrawProps.total) : ''}</h1>
                        <p>Today | This Week | This month</p>
                        <i><Icon.EmptyWallet /></i>
                    </div>
                    <div>
                        <h3>Total Utility Bills Payments</h3>
                        <h1>&#8358;0.00</h1>
                        <p>Today | This Week | This month</p>
                        <i><Icon.EmptyWallet /></i>
                    </div>
                </OverviewCardWrap>
            </CardGridWrap>
        </>
    )
}
export default CardSection;