import { axiosInstance } from "./AxiosInstance";

export interface ErrorType {
  response?: Record<string, any>;
  [key: string]: Record<string, any> | string | unknown;
}

export async function updateAccess(action:string, payload: any) {
  let response = "";
  let error: ErrorType = {

  };
  try {
    await axiosInstance
      .post(`/super/admin/category/permission/${action}`, payload)
      .then((resp) => {
        response = resp.data;
      });
  } catch (err: any) {
    error = err;
  }

  return { response, error };
}
