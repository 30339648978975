import styled from "styled-components";

export const MainWrap = styled.div`
    width:100%;
    height:100vh;
    position:relative;
`;

export const AuthFlex = styled.div`
    display:flex;
    gap:0;
`

export const AuthLeft = styled.div`
    flex:2;
    background: url('/images/auth2.png');
    background-size:cover;
    height:100vh;
    display:flex;
    flex-direction:column;
    justify-content:center;
    color:#fff;
    padding:0 5%;
    gap:40px;

    > h3 {
        font-weight: 700;
        font-size: 30px;
    }

    @media (max-width:728px){
        display:none;
    }
`

export const AuthImage = styled.img`
    width:320px;
    display:block;
    margin: 0 auto;
`

export const AuthLogoImage = styled.img`
    width:7rem;
    position:absolute;
    bottom:2rem;
    left:5%;
`

export const AuthRight = styled.form`
    flex:3;
    background: var(--main-bg);
    padding:0 5%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    position:relative;

    > div {
        width:56%;

        > h3 {
            font-weight:800;
        }
    }

    @media (max-width:728px){
        flex:1;
        height:100vh;

        > div {
            width:100%;

            > h3 {
                text-align:center;
            }
        }
    }
`

export const AuthLogoTop = styled.img`
    display:none;
    position:absolute;
    top:3rem;
    left:5%;
    width:8rem;

    @media (max-width:728px){
        display:block;
    }
`

export const InputWrap = styled.div`
    width:100%;
    margin:2rem 0;

    > button {
        width:100%;
        margin:20px 0 0 0;
    }
`

export const InputField = styled.fieldset`
    margin: 0 auto;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    text-align:left;
    margin: 0 0 1.5rem 0;
    position:relative;

    > legend {
        font-size:13px;
        padding:0 0.5rem;
        text-align:left;
        font-weight:600;
        letter-spacing: 0.0125em;
    }

    > input {
        background:#fff;
        border:none;
        padding:0rem 0 0 3%;
        font-size:14px;
        text-align:left;
        width:100%;
        color: #a5a5a5;

        @media (max-width:728px){
            font-size:16px;
            padding:0.1rem 0 0.1rem 0.6rem; 
        }
    }

    > i {
        position:absolute;
        top:13%;
        right:5%;
        transform: tramslate(0, -50%);
        cursor:pointer;
    }

    :focus-within{
       border: 1px solid #ffab01;
    }
`

export const AuthText = styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 148%;
    letter-spacing: 0.0125em;
    color: #474747;

    > span {
        color: var(--primary-color);
    }
`;

export const BlueText = styled.p`
    text-align:center;
    margin:2rem 0 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 148%;
    text-align: center;
    letter-spacing: 0.0125em;
    color: #216FED;
`

export const PinFlex = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:Center;
    margin:2rem 0;

    > p {
        font-size:25px;
        position:relative;
        top:-1.2rem;
    }

    > div {
        width:45%;

        > p {
            font-weight: 600;
            font-size: 12px;
            color: #5D5B5B;
        }
    }
`;

export const PinInputWrap = styled.div`
  display: flex;
  align-items: Center;
  justify-content: center;
  margin: 0 auto;
  padding: 0 0 1.5rem 0;
`;

export const AuthImageTop =styled.img`
    position:relative;
    width:5rem;
    display:block;
    margin:2rem auto;
`