import styled from "styled-components";

export const SettingsMainWrap = styled.div`
    padding: 20px;
    background: #FFFFFF;
    box-shadow: 0px 12px 23px rgba(62, 73, 84, 0.04);
    border-radius: 20px;
`

export const GenericHeader = styled.div`
    width:50%;
    padding:0px 10px;
    background: rgb(255,171,1, 0.05);
    border-radius: 14px;
    display:flex;
    align-items:Cenetr;
    gap:0 20px;

    @media (max-width:728px){
        width:95%;
        padding:0px 2.5%;
        gap:0 5px;
    }
`

interface GenericHeaderCard {
    color: string;
    background: string;
}

export const GenericHeaderCard = styled.h3<GenericHeaderCard>`
    font-size:16px;
    font-weight:400;
    padding: 10px 30px;
    background: ${p => p.background};
    color: ${p => p.color};

    @media (max-width:728px){
        padding:10px 15px;
    }
`

export const SettingsGrid = styled.div`
    margin: 2rem 0 0 0;
    padding:0 2%;
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap:100px;

    @media (max-width:728px){
        grid-template-columns: repeat(1, 1fr);
        gap:10px;
    }
`

export const CheckBoxFlex = styled.div`
    display:flex;
    align-items:Center;
    gap:3px;
`