import React from 'react';
import Login from '../components/Login/index';
import ConfirmEmail from '../components/confirmEmail';

const ConfirmEmailPage = () => {
    return(
        <>
            <ConfirmEmail />
        </>
    )
}

export default ConfirmEmailPage;