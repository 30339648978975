import styled from "styled-components";

export const CardGridWrap = styled.div`
    display:grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows:auto;
    gap:38px;

    @media (max-width:728px){
        grid-template-columns: repeat(2, 1fr);
        gap:15px;
    }
`

interface GridCardProps {
    background?: string;
    color?: string;
}

export const GridCard = styled.div<GridCardProps>`
    position:relative;
    background: ${p => p.background || '#fff'};
    color: ${p => p.color || '#000'};
    box-shadow: 0px 4px 20px rgba(94, 132, 194, 0.06);
    border-radius: 20px;
    padding:15px;
    display:flex;
    justify-content:space-between;
    align-items:Center;

    @media (max-width:728px){
        
        > div {
            width:60%;
        }
    }
`

export const DescriptionFlex = styled.div`
    display:flex;
    align-items:flex-start;
    gap:66px;
    margin: 2rem 0 0 0;

    @media (max-width:728px){
        flex-wrap:wrap;
    }
`

export const DescriptionCard = styled.div`
    width:60%;
    padding:20px 4%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
    border-radius: 14px;

    > div {
        margin: 0 0 2rem 0;
    }

    @media (max-width:728px){
        width:92%;
    }
`