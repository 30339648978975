import React, { useState } from "react";
import { ModalWrap, ModalChild, ModalHeader, InputField, UnderlayText } from "../../styles/reusable/index";
import * as FeatherIcon from 'react-feather';
import { Button } from "../../styles/reusable";
import { ClipLoader } from 'react-spinners';

interface PropArgs {
    closeFunc: any;
    name?: string;
    setName?: any;
    description?: string;
    setDescription: React.Dispatch<React.SetStateAction<string>>;
    action?: any;
    onProcess?: boolean;
}

const CreateCategory = ({
    closeFunc,
    name,
    setName,
    description,
    setDescription,
    action,
    onProcess
}: PropArgs) => {
    return (
        <>
            <ModalWrap>
                <ModalChild>
                    <ModalHeader>
                        <h3>Category</h3>
                        <i><FeatherIcon.X onClick={() => closeFunc()} /></i>
                    </ModalHeader>
                    <form onSubmit={(e) => action(e)}>
                        <UnderlayText>
                            Please Enter a New Role Permission that you want to create
                        </UnderlayText>
                        <InputField>
                            <legend>Service</legend>
                            <input
                                required
                                placeholder={'Enter service'}
                                type='text'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </InputField>

                        <InputField>
                            <legend>Description</legend>
                            <textarea required
                                placeholder={'Enter role description'} 
                                value={description}
                                onChange={(e) => setDescription(e.target.value)} 
                                name="" id="" cols={30} rows={10}>

                            </textarea>
                            {/* <input
                                required
                                placeholder={'Enter role description'}
                                type='text'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            /> */}
                        </InputField>

                        <Button
                            width='100%'
                            bg='var(--primary-color)'
                            color='#fff'
                            type='submit'
                            disabled={onProcess}
                            style={onProcess ? {
                                cursor: 'not-allowed',
                            } : {}}
                        >
                            {onProcess ? <ClipLoader color='#fff' size={18} /> : 'Create'}
                        </Button>
                    </form>
                </ModalChild>
            </ModalWrap>
        </>
    )
}

export default CreateCategory;
