import React, { useState } from 'react';
import { MainWrap, AuthFlex, AuthLeft, AuthRight, AuthLogoTop, AuthImage, AuthLogoImage, InputField } from '../../styles/authentication';
import { InputWrap } from '../../styles/authentication';
import { Button } from '../../styles/reusable';
import Error from '../mPos/reusable/error';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router';
import axios from 'axios';
import * as Icon from 'react-feather';

const Login = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [onProcess, setOnProcess] = useState<boolean>(false);
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    // Log User IN

    const basicPayload = {
        email: email,
        password: password
    }

    const LoginAction = async (e:any) => {
        const body = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(basicPayload)
        }
        setOnProcess(true);
        e.preventDefault();
        axios.post(`${process.env.REACT_APP_BASE_URL}/auth/admins/login`, basicPayload)
        .then(async res => {
                setOnProcess(false);
                navigate(`/confirm-email/${email}`);
        }).catch(err => {
            setOnProcess(false);
            setError(err.response.data.message);
            setTimeout(() => {
                setError('');
            }, 4000)
        })
    }

    return(
        <>
            <MainWrap>
                <AuthFlex>
                    <AuthLeft>
                        <h3>
                            Best solutions & <br />
                            value-for your business
                        </h3>
                        <AuthImage 
                            src='/images/auth.png'
                            alt='Mongoro'
                        />
                        <AuthLogoImage 
                            src='/mongoro-white.png'
                            alt='Mongoro'
                        />
                    </AuthLeft>
                    <AuthRight onSubmit={(e) => LoginAction(e)}>
                        <AuthLogoTop 
                            src='/mongoro.png'
                            alt='Mongoro'
                        />
                        <div>
                            <h3>Mongoro Administration</h3>
                            {
                                error !== '' ?
                                    <Error 
                                        message={error}
                                    />
                                : null
                            }
                            
                            <InputWrap>
                                <InputField>
                                    <legend>Email</legend>
                                    <input 
                                        placeholder='Email Address'
                                        autoComplete="off"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </InputField>
                                <InputField>
                                    <legend>Password</legend>
                                    <input 
                                        type={showPassword ? 'text': 'password'}
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                        autoComplete="off"
                                    />
                                    {
                                        showPassword ?
                                            <i>
                                                <Icon.EyeOff 
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            </i>
                                            :
                                            <i>
                                                <Icon.Eye 
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            </i>
                                    }
                                    
                                </InputField>
                                <Button
                                    bg='var(--primary-color)'
                                    color='#fff'
                                    type='submit'
                                    disabled={onProcess}
                                    style={
                                        onProcess ? {cursor: 'not-allowed'} : {cursor: 'pointer'}
                                    }
                                >
                                    {onProcess ? 
                                        <ClipLoader 
                                            color='#fff' 
                                            size={17}
                                        /> 
                                        : 
                                        'Login'
                                    }
                                </Button>
                            </InputWrap>
                        </div>
                    </AuthRight>
                </AuthFlex>
            </MainWrap>
        </>
    )
}

export default Login;