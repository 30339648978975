import React from 'react';
import Account from '../components/account';

const AccountIndex = () => {
    return(
        <>
            <Account />
        </>
    )
}

export default AccountIndex;