import React, { useState } from 'react';
import { MainWrap, AuthFlex, AuthLeft, AuthRight, AuthLogoTop, AuthImage, AuthLogoImage, InputField } from '../../styles/authentication';
import { InputWrap } from '../../styles/authentication';
import { Button } from '../../styles/reusable';
import Error from '../mPos/reusable/error';
import { ClipLoader } from 'react-spinners';
import { useNavigate } from 'react-router';
import axios from 'axios';
import Alert from '../mPos/reusable/alert';

const CreateSuperAdmin = () => {

    const navigate = useNavigate();
    const [email, setEmail] = useState<string>('');
    const [onProcess, setOnProcess] = useState<boolean>(false);
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)

    // Log User IN

    const basicPayload = {
        email: email,
    }

    const LoginAction = (e:any) => {
        e.preventDefault();
        setOnProcess(true);
        axios.post(`${process.env.REACT_APP_BASE_URL}/super/admin/create`, basicPayload)
        .then(res => {
            setOnProcess(false);
            setSuccess(true);
            setTimeout(() => {
                navigate('/login');
            }, 4000)
        }).catch(err => {
            setOnProcess(false);
            setError(err.response.data.message);
            setTimeout(() => {
                setError('');
            }, 4000)
        })
    }

    return(
        <>
            {
                success ? 
                    <Alert
                        closeFunc={() => setSuccess(false)}
                        img='/icons/success.png'
                        message='Account created!'
                        miniMessage='Your account has been created. We have sent you an email containing your password. Redirecting to login...'
                    />
                :null
            }
            <MainWrap>
                <AuthFlex>
                    <AuthLeft>
                        <h3>
                            Best solutions & <br />
                            value-for your business
                        </h3>
                        <AuthImage 
                            src='/images/auth.png'
                            alt='Mongoro'
                        />
                        <AuthLogoImage 
                            src='/mongoro-white.png'
                            alt='Mongoro'
                        />
                    </AuthLeft>
                    <AuthRight onSubmit={(e) => LoginAction(e)}>
                        <AuthLogoTop 
                            src='/mongoro.png'
                            alt='Mongoro'
                        />
                        <div>
                            <h3>Mongoro Administration</h3>
                            {
                                error !== '' ?
                                    <Error 
                                        message={error}
                                    />
                                : null
                            }
                            
                            <InputWrap>
                                <InputField>
                                    <legend>Email</legend>
                                    <input 
                                        placeholder='Email Address'
                                        autoComplete="off"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </InputField>
                                <Button
                                    bg='var(--primary-color)'
                                    color='#fff'
                                    type='submit'
                                    disabled={onProcess}
                                    style={
                                        onProcess ? {cursor: 'not-allowed'} : {cursor: 'pointer'}
                                    }
                                >
                                    {onProcess ? 
                                        <ClipLoader 
                                            color='#fff' 
                                            size={17}
                                        /> 
                                        : 
                                        'Create'
                                    }
                                </Button>
                            </InputWrap>
                        </div>
                    </AuthRight>
                </AuthFlex>
            </MainWrap>
        </>
    )
}

export default CreateSuperAdmin;