import React, { useEffect, useState } from 'react';
import { AccountPopUp, IconSection, LeftCont, LoggedAvatar, LoggedSection, MainHeaderCont, MobileMenuController, NotificationCount, RightCont } from '../../../styles/reusable/header';
import * as Icon from 'iconsax-react';
import { SortRounded } from '@mui/icons-material';
import SideBarWidget from './sidebar';
import { Line } from '../../../styles/reusable/index';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

const Header = () => {

    const navigate = useNavigate();
    
    // States
    const [showAccountPopUp, setShowAccountPopUp] = useState(false);
    const [adminInformation, setAdminInformation] = useState<any>({})

    const [openNav, setOpenNav] = useState(false);

    // Open and Close Nav Bar
    const toggleNavBar = () => {
        setOpenNav(!openNav);
    }

    // Onload Calls
    useEffect(() => {
        const loggedAdmin = JSON.parse(localStorage.getItem('loggedAdmin') || '{}');
        setAdminInformation(loggedAdmin)
    }, [])

    // Log User Out of app

    const redirectAfterLogOut = () => {
        localStorage.clear();
        navigate('/login');
    }

    return(
        <>
            <MainHeaderCont onClick={() => setShowAccountPopUp(false)}>
                <MobileMenuController>
                    <i>
                        <SortRounded 
                            onClick={() => toggleNavBar()}
                        />
                    </i>
                </MobileMenuController>
                <LeftCont>
                    {/* <i>
                        <Icon.SearchNormal1 size={18} />
                    </i>
                    <input 
                        placeholder='Search'
                    /> */}
                </LeftCont>
                <RightCont>
                    <IconSection>
                        <i>
                            <NotificationCount
                                bg='#216FED'
                            >0</NotificationCount>
                            <Icon.NotificationBing size={18} />
                        </i>
                        <i>
                            <NotificationCount
                                bg='#32D16D'
                            >0</NotificationCount>
                            <Icon.Message size={18} />
                        </i>
                        <i>
                            <NotificationCount
                                bg='#FF8723'
                            >0</NotificationCount>
                            <Icon.MessageText size={18} />
                        </i>
                    </IconSection>
                    <LoggedSection>
                        <LoggedAvatar>
                            {adminInformation && adminInformation.email ? adminInformation.email[0].toUpperCase() : ''}
                        </LoggedAvatar>
                        <div>
                            <h3>{adminInformation && adminInformation.email ? adminInformation.email : ''}</h3>
                            <p>{adminInformation && adminInformation.category ? adminInformation.category : ''}</p>
                        </div>
                        <Icon.ArrowDown2 
                            size={18} 
                            style={{
                                cursor: 'pointer'
                            }}
                            onClick={(e) => {
                                setShowAccountPopUp(!showAccountPopUp)
                                e.stopPropagation();
                            }}
                        />
                        {
                            showAccountPopUp ?
                                <AccountPopUp>
                                    <Link to='/settings'>
                                        <div>
                                            <Icon.Setting2 />
                                            <p>General Settings</p>
                                        </div>
                                    </Link>
                                    <Line />
                                    <div
                                        onClick={() => redirectAfterLogOut()}
                                    >
                                        <img 
                                            src='/icons/logout.png'
                                            alt='Logout'
                                        />
                                        <p>Logout</p>
                                    </div>
                                </AccountPopUp>
                            : null
                        }
                    </LoggedSection>
                </RightCont>
            </MainHeaderCont>

            {
                openNav ? 
                    <SideBarWidget closeNav={toggleNavBar} />
                : null
            }
        </>
    )
}

export default Header;
