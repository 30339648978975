import React from "react";
import { ModalWrap, ModalChild, ModalHeader, InputField } from "../../styles/reusable/index";
import * as FeatherIcon from 'react-feather';
import { Button } from "../../styles/reusable";
import { ClipLoader } from 'react-spinners';

interface PropArgs {
    closeFunc: any;
    setUsername: any;
    setOption: any;
    setAmount: any;
    setMethod: any;
    setDescription: any;
    handleAction: any;
    onProcess: boolean
}

const CreateTicket = ({
    closeFunc, 
    setUsername, 
    setOption, 
    setAmount, 
    setMethod,
    setDescription,
    handleAction,
    onProcess
} : PropArgs) => {
    return(
        <>
            <ModalWrap>
                <ModalChild>
                    <ModalHeader>
                        <h3>Create Ticket</h3>
                        <i><FeatherIcon.X onClick={() => closeFunc()} /></i>
                    </ModalHeader>
                    <form onSubmit={(e) => handleAction(e)}>
                        <InputField>
                            <legend>Username</legend>
                            <input 
                                placeholder={'Enter Username'}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </InputField>
                        <InputField>
                            <legend>Pay Option</legend>
                            <select onChange={(e) => setOption(e.target.value)}>
                                <option>Pay before Service</option>
                                <option>Transfer</option>
                            </select>
                        </InputField>
                        <InputField>
                            <legend>Amount(&#8358;)</legend>
                            <input 
                                placeholder={'0'}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </InputField>
                        <InputField>
                            <legend>Payment Method</legend>
                            <select
                                onChange={(e) => setMethod(e.target.value)}
                            >
                                <option>Select Payment Method</option>
                                <option value="mpos">mPos</option>
                            </select>
                        </InputField>
                        <InputField>
                            <legend>Description</legend>
                            <textarea
                                placeholder='Description'
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea>
                        </InputField>
                        <Button
                            type='submit'
                            width='100%'
                            bg='var(--primary-color)'
                            color='#fff'
                            disabled={onProcess}
                            style={ onProcess ? {cursor: 'not-allowed'} : {}}
                        >
                            {onProcess ? <ClipLoader color='#fff' size={18} /> : 'Create New Ticket'}
                        </Button>
                    </form>
                </ModalChild>
            </ModalWrap>
        </>
    )
}

export default CreateTicket;