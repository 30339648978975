import React, { useEffect, useState } from 'react';
import { DashboardMainFlex, DashboardMainBody, AppMainBody, DescHeader, Line, UserCard, IconCard, BasicFlex, GreyText, UserProfileCard, GridTexts, BalanceCard } from '../../styles/reusable/index';
import SideBarWidget from '../mPos/reusable/sidebar';
import { MainCard } from '../../styles/dashboard/cardStyle';
import Header from '../mPos/reusable/header';
import * as Icon from 'iconsax-react'
import { DescriptionCard, DescriptionFlex } from '../../styles/tickets';
import Typography from '../mPos/reusable/typography';
import moment from 'moment';
import CommaNumber from 'comma-number';
import { AvatarImage } from '../mPos/reusable/style';

const TicketInformation = () => {

    // State
    const [ticketInformation, setTicketInformation] = useState<any>({})

    useEffect(() => {
        const selected = JSON.parse(localStorage.getItem('ticket') || '{}');

        setTicketInformation(selected);
    }, [])

    const descriptiveData = [
        {
            title: 'Description',
            text: ticketInformation.description,
        },
        {
            title: 'Admin',
        },
    ]

    return(
        <>
            <DashboardMainFlex>
                <SideBarWidget mobileDisplay='none' />
                <DashboardMainBody>
                    <Header />
                    <AppMainBody>
                        <DescHeader>
                            <h3>Ticket ({ticketInformation.title})</h3>
                        </DescHeader>
                        <UserCard>
                            <div>
                                <div>
                                    <p>User Id</p>
                                    <h3>{ticketInformation.tiid}</h3>
                                </div>
                                <div>
                                    <BasicFlex>
                                        <IconCard background='#E0E3EB' color='#122466'>
                                            <Icon.Call />
                                        </IconCard>
                                        <div>
                                            <GreyText>PHONE</GreyText>
                                            <h3></h3>
                                        </div>
                                    </BasicFlex>
                                </div>
                            </div>
                            <Line />
                            <div>
                            <UserProfileCard>
                                {
                                    ticketInformation.image ? 
                                        <AvatarImage 
                                            src={ticketInformation.image}
                                            alt='Profile'
                                        />
                                        :
                                        <IconCard
                                            background='#FEF9E5'
                                            color='#FFAB01'
                                            style={{
                                                fontWeight: 800
                                            }}
                                        >
                                            {ticketInformation.username ? ticketInformation.username[0].toUpperCase() : ''}
                                        </IconCard>
                                }
                                        <div>
                                            <span>User</span>
                                            <h3>{`${ticketInformation.username}`}</h3>
                                            <p>{ticketInformation.email}</p>
                                        </div>
                                    </UserProfileCard>
                                {/* <GridTexts>
                                    <p>Payment Date</p>
                                    <h3>April 29, 2022</h3>
                                </GridTexts> */}
                                <GridTexts>
                                    <p>Created</p>
                                    <h3>{moment(ticketInformation.timeCreated).format('LL')}</h3>
                                </GridTexts>
                                <GridTexts>
                                    <p>Updated</p>
                                    <h3>{moment(ticketInformation.timeCreated).format('LL')}</h3>
                                </GridTexts>
                                <GridTexts>
                                    <p>Category</p>
                                    <h3>{ticketInformation.category}</h3>
                                </GridTexts>
                                <GridTexts>
                                    <p>Ticket Type</p>
                                    <h3>{ticketInformation.status}</h3>
                                </GridTexts>
                                <BalanceCard>
                                    <IconCard background='#4DC736' color='#fff'>
                                        &#8358;
                                    </IconCard>
                                    <GridTexts>
                                        <p>Amount</p>
                                        <h3>&#8358;{CommaNumber(ticketInformation.amount)}</h3>
                                    </GridTexts>
                                </BalanceCard>
                            </div>
                        </UserCard>
                        <DescriptionFlex>
                            <DescriptionCard>
                                {
                                    descriptiveData.map((item, index) => (
                                        <div key={index}>
                                            <Typography 
                                                text={item.title}
                                                fontSize='14px'
                                                color='#000'
                                                fontWeight={700}
                                            />
                                            <Typography 
                                                text={item.text}
                                                fontSize='13px'
                                                color='#969BA0'
                                            />
                                        </div>
                                    ))
                                }
                            </DescriptionCard>
                            {/* <MainCard bgImage={`url('images/card1.png')`}>
                                <h3>Account Status:  <b>REGULAR NGN</b></h3>
                                <h1>&#8358;13,400,000</h1>
                                <p>Last Transaction   5/04/22 </p>
                            </MainCard> */}
                        </DescriptionFlex>
                    </AppMainBody>
                </DashboardMainBody>
            </DashboardMainFlex>
        </>
    )
}

export default TicketInformation;